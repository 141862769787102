import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Navbar.css";

const Navbar = () => {
    const [navOpen, setNavOpen] = useState(false);
    return (
    <nav className='nav'>
        <div className='nav-container-large-screens'>
            <div id='etherscore-logo'>
                {/*  eslint-disable-next-line */}
                <a href='#'>
                    <img src={ '/assets/images/logo-etherScore.svg'}  
                        alt="logo Etherscore" 
                        width={150}
                        height={18}
                    />
                </a>
            </div>
            <a className="nav-links" href='https://docs.etherscore.network/' target="_blank" rel="noreferrer">Documentation</a>
            <a className="nav-links" href='https://docs.etherscore.network/product/roadmap' target="_blank" rel="noreferrer">Roadmap</a>
            {/*  eslint-disable-next-line */}
            {/* 
            <a className="nav-links" href='#'>For creators</a>
            */}
            <a href="https://beta.etherscore.network/" target="_blank" rel="noreferrer">
              <button id='nav-app'>App</button>
            </a> 
        </div>
        
        {/*  responsive  */}
        <div className='nav-container-responsive'>
          <div className="navbar">
            <div id='etherscore-logo-responsive'>
              {/*  eslint-disable-next-line */}
              <a href='#'>
                  <img src={ '/assets/images/logo-etherScore.svg'}  
                      alt="logo Etherscore" 
                      width={130}
                      height={15}
                  />
              </a>
            </div>
      
            <div className="menu-toggle" onClick={() => setNavOpen(!navOpen)}>
              <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
                <span
                  className={navOpen ? "lineBottom spin" : "lineBottom"}
                ></span>
              </div>
            </div>
          </div>

          <div
            className="nav-overlay"
            style={{
              top: navOpen ? "0" : "-100%",
              transitionDelay: navOpen ? "0s" : "0s",
            }}
          >
            <ul className="nav-links">
              <li className="nav-item">
                <Link
                  to="https://docs.etherscore.network/"
                  onClick={() => setNavOpen(!navOpen)}
                  style={{
                    top: navOpen ? "0" : "120px",
                    transitionDelay: navOpen ? "0.8s" : "0s",
                  }}
                >
                  Documentation
                </Link>
                <div className="nav-item-wrapper"></div>
              </li>
              <li className="nav-item">
                <Link
                  to="https://docs.etherscore.network/product/roadmap"
                  onClick={() => setNavOpen(!navOpen)}
                  style={{
                    top: navOpen ? "0" : "120px",
                    transitionDelay: navOpen ? "0.9s" : "0s",
                  }}
                >
                  Roadmap
                </Link>
                <div className="nav-item-wrapper"></div>
              </li>
              {/* 
              <li className="nav-item">
                <Link
                  to="/"
                  onClick={() => setNavOpen(!navOpen)}
                  style={{
                    top: navOpen ? "0" : "120px",
                    transitionDelay: navOpen ? "1s" : "0s",
                  }}
                >
                  For creators
                </Link>
                <div className="nav-item-wrapper"></div>
              </li>
              */}
            </ul>
          </div>
        </div>
    </nav>
    );
};

export default Navbar;
