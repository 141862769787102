import Reputation from "../animations/features/Reputation.json";
import Shine from "../animations/features/Shine.json";
import Reward from "../animations/features/Reward.json";
import Oneplace from "../animations/features/Oneplace.json";
import Blockchain from "../animations/features/Blockchain.json";

export const featuresData = {
    "reputation": {
        title:'Build',
        strongTitle: "reputation",
        desc: "Collect badges based on your Dapps activity to grow your on-chain reputation.",
        icon:'/assets/images/icons/features/Landing-Reputation.svg',
        animationData: Reputation
      },
    "shining": {
        title:'Start',
        strongTitle: "shining",
        desc: "Share your personalized EtherScore profile with your friends and communities to showcase your Web3 contributions.",
        icon:'/assets/images/icons/features/Landing-Shine.svg',
        animationData: Shine
      },
    "rewarded": {
        title:'Get',
        strongTitle: "rewarded",
        desc: "Badges are unique trophies of your victories, but they also grant you access to specific benefits and rewards, either individually or as groups.",
        icon:'/assets/images/icons/features/Landing-Rewards.svg',
        animationData: Reward
      },
    "one place": {
        title:'All in',
        strongTitle: "one place",
        desc: "EtherScore allows you to combine achievements from every Dapps and blockchains in a single user profile.",
        icon:'/assets/images/icons/features/Landing-Oneplace.svg',
        animationData: Oneplace
      },
    "philosophy": {
        title:'Soulbound',
        strongTitle: "tokens",
        desc: "EtherScore badges are tied to your wallet.",
        icon:'/assets/images/icons/features/Landing-Blockchain.svg',
        animationData: Blockchain
      },
}

export const partnersIcons = {
    "Lens": {
        hoverIcon:'/assets/images/icons/features/partners-lens-icon.png',
        icon: '/assets/images/icons/features/partners-lens-hover-icon.png',
        },
    "Gitcoin": {
        hoverIcon:'/assets/images/icons/features/partners-gitcoin-icon.png',
        icon: '/assets/images/icons/features/partners-gitcoin-hover-icon.png',
        },
    "Uniswap": {
        hoverIcon:'/assets/images/icons/features/partners-uniswap-icon.png',
        icon: '/assets/images/icons/features/partners-uniswap-hover-icon.png',
        },
    "Snapshot": {
        hoverIcon:'/assets/images/icons/features/partners-snapshot-icon.png',
        icon: '/assets/images/icons/features/partners-snapshot-hover-icon.png',
        },
    "Ens": {
        hoverIcon:'/assets/images/icons/features/partners-ens-icon.png',
        icon: '/assets/images/icons/features/partners-ens-hover-icon.png',
        },
    "Mirror": {
        hoverIcon:'/assets/images/icons/features/partners-mirror-icon.png',
        icon: '/assets/images/icons/features/partners-mirror-hover-icon.png',
    },
    "Kleros": {
        hoverIcon:'/assets/images/icons/features/partners-kleros-icon.png',
        icon: '/assets/images/icons/features/partners-kleros-hover-icon.png',
    },
    "Compound": {
        hoverIcon:'/assets/images/icons/features/partners-compound-icon.png',
        icon: '/assets/images/icons/features/partners-compound-hover-icon.png',
    },
    "Aave": {
        hoverIcon:'/assets/images/icons/features/partners-aave-icon.png',
        icon: '/assets/images/icons/features/partners-aave-hover-icon.png',
    },
    "RocketPool": {
        hoverIcon:'/assets/images/icons/features/partners-rocketpool-icon.png',
        icon: '/assets/images/icons/features/partners-rocketpool-hover-icon.png',
    },
    "Poap": {
        hoverIcon:'/assets/images/icons/features/partners-poap-icon.png',
        icon: '/assets/images/icons/features/partners-poap-hover-icon.png',
    },
    "Curve": {
        hoverIcon:'/assets/images/icons/features/partners-curve-icon.png',
        icon: '/assets/images/icons/features/partners-curve-hover-icon.png',
    },
    "Balancer": {
        hoverIcon:'/assets/images/icons/features/partners-balancer-icon.png',
        icon: '/assets/images/icons/features/partners-balancer-hover-icon.png',
    },
    "Sushiswap": {
        hoverIcon:'/assets/images/icons/features/partners-sushiswap-icon.png',
        icon: '/assets/images/icons/features/partners-sushiswap-hover-icon.png',
    },
    "Stargate": {
        hoverIcon:'/assets/images/icons/features/partners-stargate-icon.png',
        icon: '/assets/images/icons/features/partners-stargate-hover-icon.png',
    },
    "Multichain": {
        hoverIcon:'/assets/images/icons/features/partners-multichain-icon.png',
        icon: '/assets/images/icons/features/partners-multichain-hover-icon.png',
    },
    "Arbitrum": {
        hoverIcon:'/assets/images/icons/features/partners-arbitrum-icon.png',
        icon: '/assets/images/icons/features/partners-arbitrum-hover-icon.png',
    },
    "UnstopableDomains": {
        hoverIcon:'/assets/images/icons/features/partners-unstopabledomains-icon.png',
        icon: '/assets/images/icons/features/partners-unstopabledomains-hover-icon.png',
    },
    "Optimism": {
        hoverIcon:'/assets/images/icons/features/partners-optimism-icon.png',
        icon: '/assets/images/icons/features/partners-optimism-hover-icon.png',
    },
    "Zksync": {
        hoverIcon:'/assets/images/icons/features/partners-zksync-icon.png',
        icon: '/assets/images/icons/features/partners-zksync-hover-icon.png',
    },
    "Lido": {
        hoverIcon:'/assets/images/icons/features/partners-lido-icon.png',
        icon: '/assets/images/icons/features/partners-lido-hover-icon.png',
    },
    "PoH": {
        hoverIcon:'/assets/images/icons/features/partners-poh-icon.png',
        icon: '/assets/images/icons/features/partners-poh-hover-icon.png',
    },
    "Celer": {
        hoverIcon:'/assets/images/icons/features/partners-celer-icon.png',
        icon: '/assets/images/icons/features/partners-celer-hover-icon.png',
    },
    "Wormhole": {
        hoverIcon:'/assets/images/icons/features/partners-wormhole-icon.png',
        icon: '/assets/images/icons/features/partners-wormhole-hover-icon.png',
    },
    "Starknet": {
        hoverIcon:'/assets/images/icons/features/partners-starknet-icon.png',
        icon: '/assets/images/icons/features/partners-starknet-hover-icon.png',
    },
}


export const values = {
    "Team": {
        icon:'/assets/images/icons/features/Landing-Team.svg',
        title:"You're",
        strongTitle:"part of the team",
        desc:'EtherScore revolves around its community — our main goal is to include you in the project so that we can learn from the best.'
    },
    "Farming": {
        icon:'/assets/images/icons/features/Landing-No-farming.svg',
        title:"No",
        strongTitle:"farming",
        desc:'It’s not about unlocking random achievements, it’s about making your actions shine and building your reputation through community-driven actions.'
    },
    "Ethics": {
        icon:'/assets/images/icons/features/Landing-Real-ethics.svg',
        title:"Web3",
        strongTitle:"ethics",
        desc:'We want to improve relations between Dapps and users to build a fair Web3, give you a fulfilling experience and reward actions that have real meanings in the ecosystem.'
    },
}


export const upcoming = {
    "Alpha version": {
        title:'Alpha version',
        status: 'Released',
        desc:'Bootstrapping of the EtherScore DAO with a first collection of badges that grant DAO access and voting rights.'
    },
    "Beta version":{
        title:'Beta version',
        status: 'Released',
        desc:'User-friendly UI and many new features, including user profile personalization, multi-level badging, and revocability.'
    },
    "Badge Factory":{
        title:'Badge Factory',
        status: 'Upcoming',
        desc:'A no-code tool for Dapps and communities to deploy their own EtherScore badges.'
    },
}
