import React, { useState, useEffect} from 'react';
import "../styles/Features.css";
import Lottie from "lottie-react";
import ContainerLottie from "./container-lottie"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import 'swiper/swiper.min.css';
import BadgeFactory from "../animations/BadgeFactory.json";
import {featuresData, partnersIcons, values} from "../utils/data"

const Features = () => {
    // eslint-disable-next-line
    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [slidesPerView, setSlidesPerView] = useState(6);
    
      const style = {
        transform: 'scale(1.25)',
        border: '1px solid transparent',
        marginBottom: '0.75em',
        overflow: 'hidden',
        borderRadius: '10px 10px 0px 0px',
      };

    const mobileStyle = {
        transform: 'scale(2.75)',
        border: '1px solid transparent',
        borderRadius: '10px 10px 0px 0px',
        marginTop: '0.75em'
    };

    const handleMouseOver = (key) => {
        setHoveredFeature(key);
        setHoveredIcon(key)
    };
    
    const handleMouseOut = () => {
        setHoveredFeature(null);
        setHoveredIcon(null)
    };

    const handleMediaQuery = (e) => {
        setIsMobile(e.matches);
    };

    const updateSlidesPerView = () => {
        if (window.innerWidth <= 900) {
            setSlidesPerView(3);
        } else {
            setSlidesPerView(6);
        }
    };
    
  
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 900px)');
        setIsMobile(mediaQuery.matches);
        mediaQuery.addListener(handleMediaQuery);
        return () => {
            mediaQuery.removeListener(handleMediaQuery);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateSlidesPerView);
        updateSlidesPerView();
    
        return () => {
          window.removeEventListener('resize', updateSlidesPerView);
        };
    }, []);
    

    return (
    <div id="features">
        <div className="features-container">
            {Object.keys(featuresData).map((key, index)=>(
                <ContainerLottie key={index} title={featuresData[key]['title']} strongTitle={featuresData[key]['strongTitle']} desc={featuresData[key]['desc']} animationData={featuresData[key]['animationData']} />
            ))}
        </div>
        <div className="partners-container">
            <h1>
                They'll tell us <strong>how great you are</strong>
            </h1>
            <p>The collection of EtherScore badges already includes most used Dapps, blockchains and communities.</p>
            <div className="partner-img-container">
                <Swiper spaceBetween={10} 
                        slidesPerView={slidesPerView} 
                        style={{zIndex:'0', height:'7em'}}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        
                        pagination={{
                            clickable: true,
                        }}
                        
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                >
            {Object.keys(partnersIcons).map((key, index) => (
                <SwiperSlide key={index}>
                    <div /*key={index}*/
                    onMouseOver={() => handleMouseOver(key)} 
                    onMouseOut={handleMouseOut}>
                        <img
                            src={hoveredIcon === key ? partnersIcons[key]["hoverIcon"] : partnersIcons[key]["icon"]}
                            alt="partners icon"
                            width={80}
                            height={80}
                        />
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
            </div>
        </div>
        <div className='factory-container'>
            <div className="factory-intro">
                <h1 style={{color:'black'}}>
                    <strong style={{color:'black'}}>Awesome badges</strong><br />for your <strong style={{color:'black'}}>awesome community</strong>
                </h1>
                <p style={{color:'black'}}>
                    Create achievements for your own project through our no-code badge factory,
                    <br/> 
                    and grant your users benefits that will put a smile on their face.
                </p>
                <button id='learn-more'>Coming soon</button> 
            </div>
            <div className='factory'>
                <div className='square-mask'>
                    <div className='square-content'>
                        <Lottie animationData={BadgeFactory} style={isMobile ? mobileStyle : style} />
                    </div>
                </div>
            </div>
        </div>
        <div className="values-container">
            {Object.keys(values).map((key, index) => (
            <div key={index} >
                <div className="values-data">
                    <div className="icon-values-container">
                        <img src={values[key]["icon"]}  
                                alt="upcming icon" 
                                width={70}
                                height={70}
                        />
                        <div>
                            <hr style={{ 
                            border: "none",
                            borderLeft: "1px solid white",
                            height: "30px",
                            marginTop: '10px',
                            marginLeft: '10px'
                            }} />
                        </div>
                        <h1 style={{marginTop:'15px', fontFamily: "Inter-Light"}}>
                            {values[key]["title"]}
                            <br />
                            <strong>
                                {values[key]["strongTitle"]}
                            </strong>
                        </h1>
                        <div className="paragraph"
                             style={{display:'flex', 
                                     flexDirection:'row', 
                                     alignItems: 'center', 
                                     gap: '1em', 
                                     marginTop: '-15px',
                                     marginLeft: '10px'
                                    }}
                        >
                            <div>
                                <hr style={{ 
                                border: "none",
                                borderLeft: "1px solid white",
                                height: "90px"
                                }} />
                            </div>
                            <p> {values[key]["desc"]}</p>
                        </div>
                </div>
            </div>
            </div>
            ))}
        </div>
    </div>
    );
}

export default Features;