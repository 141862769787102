import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Lottie from "lottie-react";
import './App.css';
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Star from "./animations/Star.json";
import Check from "./animations/Check.json";
import ETH from "./animations/ETH.json";
import Reward from "./animations/Reward.json";

function App() {
  const [background, setBackground] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const starStyle = {
      position: 'absolute',
      right: '-20%',
      marginTop: '-6%',
      width: "38%",
      visibility: "visible",
      zIndex: 0,
  };

  const style = {
    position: 'absolute',
    left: '-17%',
    marginTop: '9%',
  //height: "40%",
    width: "35%",
    display: '',
  };

  const styleLottieETH = {
      width: isMobile ? "55%" : "35%",
      position: 'absolute',
      left: isMobile ? "-25%" : '-15%',
      marginTop: isMobile ? "-63%" : '-50%',
      display:'',
  };

  const styleLottieReward = {
      width: isMobile ? "45%" : "35%",
      position: 'absolute',
      right: isMobile ? "-16%" : '-13%',
      marginTop:'-44%',
      display:'',
  };

  const handleMediaQuery = (e) => {
    setIsMobile(e.matches);
  };

  const handleResizeBackground = () => {
    const width = window.innerWidth;
    if (width >= 4310) {
      setBackground('none');
    } else {
      setBackground('');
    }
  };

  const handleZoomChange = () => {
    const zoomLevel = Math.round(window.devicePixelRatio * 100/2);
    if (zoomLevel <= 33 && !isZoomed) {
      setIsZoomed(true);
    } else if (zoomLevel > 33 && isZoomed) {
      setIsZoomed(false);
    }
  };

  useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 1250px)');
        setIsMobile(mediaQuery.matches);
        mediaQuery.addListener(handleMediaQuery);
        return () => {
            mediaQuery.removeListener(handleMediaQuery);
        };
        // eslint-disable-next-line 
    }, []);

  useEffect(() => {
    handleResizeBackground();
    window.addEventListener('resize', handleResizeBackground);
    return () => {
      window.removeEventListener('resize', handleResizeBackground);
    };
    // eslint-disable-next-line 
  }, []);

  
  useEffect(() => {
    window.addEventListener('resize', handleZoomChange);
    return () => {
      window.removeEventListener('resize', handleZoomChange);
    };
    // eslint-disable-next-line 
  }, [isZoomed]);
  
  
  return (
    <Router>
      <div className="App" style={{background: background}} data-testid="app">
        <div className="lottie-container2" style={starStyle} >
            <Lottie animationData={Star} data-testid="lottie" />
          </div>
          <div className="lottie-container2" style={style}>
            <Lottie animationData={Check} />
          </div>
        <Navbar />
        <Intro />
        <Features />
        <Footer />
        {!isZoomed &&
        <>
          <div className="lottie-container2" style={styleLottieETH } >
            <Lottie animationData={ETH} data-testid="lottie2"  />
          </div>
          <div className="lottie-container2" >
            <Lottie animationData={Reward} style={styleLottieReward}/>
          </div>
        </>
        }
      </div>
    </Router>
  );
}

export default App;
