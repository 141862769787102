import React, { useState, useEffect } from 'react';
import Lottie from "lottie-react";

const ContainerLottie=(props)=>{

    const [animation, setAnimation]=useState(false)

    const styleLottie = {
        height: 85,
        width: 85,
        margin: '0 auto',
        marginBottom: '1.2em',
    };

    const handleMouseEnter=()=>{  
            setAnimation(true)
    }

    useEffect(()=>{
        if(animation){
            setTimeout(()=>{
                setAnimation(false)
            },1800)
        }
    },[animation])


return(
    <div
        style={{width:"100%", height: "100%"}}
        className="lottie-container"
        onMouseEnter={() => {
            handleMouseEnter()
        }}
    >
        <Lottie
            animationData={props.animationData}
            style={styleLottie}
            loop={animation  ? 0.5 : false}
            autoplay={animation ? 0.5 : false}
        />
        <div className="data">
            <h1>
            {props.title}
            <br />
                <strong>{props.strongTitle}</strong>
            </h1>
            <p>{props.desc}</p>
        </div>
    </div>
    )  
}

export default ContainerLottie;